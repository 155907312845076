import React, { useState } from 'react';


import heroimg from '../assets/hero.png'
import team1 from "../assets/team-1.jpg";
import team2 from "../assets/team-2.jpg";
import team3 from "../assets/team-3.jpg";
import testimonialimg1 from "../assets/testimonial-1.jpg"
import testimonialimg2 from "../assets/testimonial-2.jpg"
import testimonialimg3 from "../assets/testimonial-3.jpg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ServicesComponent from '../components/Services/index';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
function Home() {
    const options = {
        autoplay: true,
        smartSpeed: 1000,
        margin: 25,
        dots: false,
        loop: true,
        nav: true,
        navText: [
            '<i class="bi bi-chevron-left"></i>',
            '<i class="bi bi-chevron-right"></i>'
        ],
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            }
        }
    };
    const handleclick = () => {
        window.scrollTo({
            top:820,
            behavior: 'smooth'
        });
    }
    const [hasStarted, setHasStarted] = useState(false);
    return (
        <>
            <div className="container-xxl bg-primary hero-header">
                <div className="container px-lg-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h1 className="text-white mb-4 animated slideInDown">A Digital Agency of Imaginative & Skilled Experts</h1>
                            <p className="text-white pb-3 animated slideInDown"> We blend intelligence and creativity to deliver exceptional digital solutions. We turn your ideas into reality with innovative designs and cutting-edge technology.</p>
                            <button onClick={handleclick} className="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft" >Read More</button>
                            <a href="/contact?section=middle" className="btn btn-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                        </div>
                        <div className="col-lg-6 text-center text-lg-start">
                            <img className="img-fluid animated zoomIn" src={heroimg} alt=""></img>
                        </div>
                    </div>
                </div>
            </div >


            <ServicesComponent />



            <div className="container-xxl bg-primary fact py-5 wow fadeInUp" data-wow-delay="0.1s">

                <div className="container py-5 px-lg-5">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                            <i className="fa fa-certificate fa-3x text-secondary mb-3"></i>
                            <h1 className="text-white mb-2" data-toggle="counter-up">  <CountUp delay={0.1} end={hasStarted ? 6 : 0} start={0} duration={2}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} >
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp></h1>
                            <p className="text-white mb-0">Years Experience</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                            <i className="fa fa-users-cog fa-3x text-secondary mb-3"></i>
                            <h1 className="text-white mb-2" data-toggle="counter-up">  <CountUp end={hasStarted ? 10 : 0} start={0} duration={2} >
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={(isVisible) => {
                                        if (isVisible) {
                                            start();
                                            setHasStarted(true);
                                        }
                                    }}>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp></h1>
                            <p className="text-white mb-0">Team Members</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                            <i className="fa fa-users fa-3x text-secondary mb-3"></i>

                            <h1 className="text-white mb-2" >
                                <CountUp end={hasStarted ? 50 : 0} start={0} duration={2}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} >
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </h1>
                            <p className="text-white mb-0">Satisfied Clients</p>
                        </div>
                        <div className="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                            <i className="fa fa-check fa-3x text-secondary mb-3"></i>
                            <h1 className="text-white mb-2" data-toggle="counter-up">  <CountUp end={hasStarted ? 100 : 0} start={0} duration={2}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} >
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp></h1>
                            <p className="text-white mb-0">Compleate Projects</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl  wow fadeInUp" data-wow-delay="0.1s">
                <div className="container  px-lg-5">
                    <p className="section-title text-secondary justify-content-center">
                        <span></span>Testimonial<span></span>
                    </p>
                    <h1 className="text-center mb-5">What Say Our Clients!</h1>
                    <OwlCarousel className="owl-theme testimonial-carousel" {...options}>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>Working with Brainscraft Technologies was a game-changer for our company. Their expertise in MERN Stack development and data analysis helped us streamline our processes and enhance our product offerings.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg1} alt="Client 1" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">Maria Zannes</h5>
                                    <span>CTO, Education Analytics Company</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>Brainscraft Technologies transformed our online presence with their exceptional UI/UX design and SEO services.Our website's traffic and engagement have significantly increased,leading to higher conversions.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg2} alt="Client 2" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">David Logan</h5>
                                    <span>Great-IT ApS</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>The team at Brainscraft Technologies was a pleasure to work with. They provided innovative solutions for our machine learning needs and were always available to address any questions or concerns.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg3} alt="Client 3" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">James Turner</h5>
                                    <span>Marketing & E-commerce:</span>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>

            <div className="container-xxl bg-primary newsletter py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5 px-lg-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 text-center">
                            <p className="section-title text-white justify-content-center"><span></span>Newsletter<span></span></p>
                            <h1 className="text-center text-white mb-4">Stay Always In Touch</h1>
                            <p className="text-white mb-4">Stay updated with the latest news and insights from Brainscraft.</p>
                            <div className="position-relative w-100 mt-3">
                                <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" style={{ height: "48" }} />
                                <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl">
                <div className="container  px-lg-5">
                    <div className="wow fadeInUp" data-wow-delay="0.1s">
                        <p className="section-title text-secondary justify-content-center"><span></span>Our Team<span></span></p>
                        <h1 className="text-center mb-5">Our Team Members</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item bg-light rounded">
                                <div className="text-center border-bottom p-4">
                                    <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team1} alt="Team member 1" />
                                    <h5>Muhammad Asim</h5>
                                    <span>Senior Full Stack Developer</span>
                                </div>
                                <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/MuhammadMirzaAsim?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/muhammadasimmirza/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item bg-light rounded">
                                <div className="text-center border-bottom p-4">
                                    <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team2} alt="Team member 2" />
                                    <h5>Hanad Muqadar</h5>
                                    <span>Data Scientist</span>
                                </div>
                                <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/syedhanad?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/hanad-muqaddar-91a450130/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item bg-light rounded">
                                <div className="text-center border-bottom p-4">
                                    <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team3} alt="Team member 3" />
                                    <h5>Muhammad Bilal</h5>
                                    <span>Frontend Developer</span>
                                </div>
                                <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/profile.php?id=100088430861303&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>

                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/mirzabilal1029/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Home