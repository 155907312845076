import React, { useEffect, useState } from 'react'

import { Link,useLocation } from "react-router-dom"

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = [];
  if (scrolled) {
    navbarClasses.push('sticky-top sticky-top shadow-sm');
  }
  const handleClick = (link) => {
  };
  return (
    <>

      <div className="container-xxl position-relative p-0">
        <nav className={"navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 " + navbarClasses.join(' ')}>
          <a href="/" className="navbar-brand p-0">
            <h1 className="m-0 ">BrainsCraft</h1>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="fa fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto py-0">

              <a className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}

                href="/">Home</a>
              <a href="/services" className={`nav-item nav-link ${location.pathname === '/services' ? 'active' : ''}`}

              >Services</a>

              <a className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`} href="/about">About</a>

              <a href="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</a>
            </div>
            <a href="/contact" className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Get Started</a>
          </div>
        </nav>

       

      </div>

    </>
  )
}

export default Navbar