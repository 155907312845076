import React from 'react'
import testimonialimg1 from "../assets/testimonial-1.jpg"
import testimonialimg2 from "../assets/testimonial-2.jpg"
import testimonialimg3 from "../assets/testimonial-3.jpg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ServicesComponent from '../components/Services';
function services() {
    const options = {
        autoplay: true,
        smartSpeed: 1000,
        margin: 25,
        dots: false,
        loop: true,
        nav: true,
        navText: [
            '<i class="bi bi-chevron-left"></i>',
            '<i class="bi bi-chevron-right"></i>'
        ],
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            }
        }
    };
  return (
    <>
    <div class="container-xxl py-5 bg-primary hero-header">
        
    <div class="container my-5 py-5 px-lg-5">
        <div class="row g-5 py-5">
            <div class="col-12 text-center">
                <h1 class="text-white animated slideInDown">Service</h1>
                <hr class="bg-white mx-auto mt-0" style={{width: "90px"}}></hr>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center">
                    <li className="breadcrumb-item text-white active" aria-current="page">Home</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Pages</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<ServicesComponent/>

      
        <div class="container-xxl bg-primary newsletter py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5 px-lg-5">
                <div class="row justify-content-center">
                    <div class="col-lg-7 text-center">
                        <p class="section-title text-white justify-content-center"><span></span>Newsletter<span></span></p>
                        <h1 class="text-center text-white mb-4">Stay Always In Touch</h1>
                        <p class="text-white mb-4">Stay updated with the latest news and insights from Brainscraft. Subscribe to our newsletter for updates on our services, industry trends, and more, directly to your inbox.</p>
                        <div class="position-relative w-100 mt-3">
                            <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" style={{height: "48px"}}></input>
                            <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i class="fa fa-paper-plane text-primary fs-4"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container-xxl  wow fadeInUp" data-wow-delay="0.1s">
                <div className="container  px-lg-5">
                    <p className="section-title text-secondary justify-content-center">
                        <span></span>Testimonial<span></span>
                    </p>
                    <h1 className="text-center mb-5">What Say Our Clients!</h1>
                    <OwlCarousel className="owl-theme testimonial-carousel" {...options}>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>Working with Brainscraft Technologies was a game-changer for our company. Their expertise in MERN Stack development and data analysis helped us streamline our processes and enhance our product offerings.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg1} alt="Client 1" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">Maria Zannes</h5>
                                    <span>CTO, Education Analytics Company</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>Brainscraft Technologies transformed our online presence with their exceptional UI/UX design and SEO services.Our website's traffic and engagement have significantly increased,leading to higher conversions.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg2} alt="Client 2" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">David Logan</h5>
                                    <span>Great-IT ApS</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item bg-light rounded my-4">
                            <p className="fs-5">
                                <i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>The team at Brainscraft Technologies was a pleasure to work with. They provided innovative solutions for our machine learning needs and were always available to address any questions or concerns.
                            </p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonialimg3} alt="Client 3" style={{ width: 65, height: 65 }} />
                                <div className="ps-4">
                                    <h5 className="mb-1">James Turner</h5>
                                    <span>Marketing & E-commerce:</span>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>

  
        
</>

  )
}

export default services