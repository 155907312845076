
import React, { useEffect } from 'react';
import ServicesCard from './ServicesCard';

const servicesData = [
  {
    icon: 'fas fa-cogs',
    seconds: '0.1s',
    title: 'Machine Learning',
    description: 'We harness the power of machine learning to help businesses make data-driven decisions, and create innovative products.',
  },
  {
    icon: 'fas fa-chart-line',
    title: 'Data Analytics',
    seconds: '0.3s',

    description: 'We empower businesses to leverage their data, providing insights for informed decisions and optimized processes.',
  },
  {
    icon: 'fas fa-code',
    title: 'Web Development',
    seconds: '0.5s',

    description: 'We specialize in creating cutting-edge, responsive, and user-friendly websites that meet the unique needs of your business. ',
  },
  {
    icon: 'fas fa-mobile-alt',
    title: 'App Development',
    seconds: '0.1s',


    description: 'We specialize in creating high-quality mobile apps tailored to your business needs. Our solutions ensure seamless functionality .',
  },
  {
    icon: 'fas fa-search',
    title: 'SEO',
    seconds: '0.3s',

    description: ' We enhance your online visibility and drive targeted traffic to your website through our comprehensive SEO services.',
  },
  {
    icon: 'fas fa-paint-brush',
    title: 'UI/UX Design',
    seconds: '0.5s',


    description: 'We craft visually appealing, user-friendly digital experiences with intuitive interfaces and seamless interactions.',
  },
];

function ServicesComponent() {

  return (
    <div class="container-xxl ">

    <div class="container  px-lg-5">
        <div class=" wow fadeInUp" data-wow-delay="0.1s">
            <p class="section-title text-secondary justify-content-center"><span></span>Our Services<span></span></p>
            <h1 class="text-center mb-5">What Solutions We Provide</h1>
        </div>
        <div class="row g-4">
        {servicesData.map((service, index) => (
        <ServicesCard
          index={index}
          seconds={service.seconds}
          icon={service.icon}
          title={service.title}
          description={service.description}
        />
      ))}
          
          
        </div>
    </div>
</div>     
  );
}

export default ServicesComponent;
