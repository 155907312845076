
import team1 from "../assets/team-1.jpg";
import team2 from "../assets/team-2.jpg";
import team3 from "../assets/team-3.jpg";


function About() {
   

    return (
        <>
            <div className="container-xxl py-5 bg-primary hero-header">
                <div className="container my-5 py-5 px-lg-5">
                    <div className="row g-5 py-5">
                        <div className="col-12 text-center">
                            <h1 className="text-white animated slideInDown">About Us</h1>
                            <hr className="bg-white mx-auto mt-0" style={{ width: "90px" }}></hr>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item text-white active" aria-current="page">Home</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Pages</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                                </ol>
                            </nav >
                        </div>
                    </div>
                </div>
            </div>
          
            <section className="about wow fadeInUp" data-wow-delay="0.1s">
                <div className="maincontainer">
                    <h2 className="abouttitle" >About Us</h2>
                    <p>Welcome to Brainscraft Technologies! We are a premier technology solutions provider dedicated to empowering businesses through cutting-edge technology. Our team of experts is committed to helping you succeed in the digital age.</p>
                    <p>At Brainscraft Technologies, we specialize in Artificial Intelligence, Data Science, Web Development, Mobile App Development, SEO, Digital Marketing. Our goal is to deliver exceptional services that help your business thrive in a competitive market.</p>
                </div>
                <div className="container-xxl wow fadeInUp" data-wow-delay="0.1s">

                    <div className="container py-5 px-lg-5">
                        <div >
                            <p className="section-title text-secondary justify-content-center"><span></span>Our Team<span></span></p>
                            <h1 className="text-center mb-5">Our Team Members</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="team-item bg-light rounded">
                                    <div className="text-center border-bottom p-4">
                                        <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team1} alt="Team member 1" />
                                        <h5>Muhammad Asim</h5>
                                        <span>Senior Full Stack Developer</span>
                                    </div>
                                    <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/MuhammadMirzaAsim?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/muhammadasimmirza/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="team-item bg-light rounded">
                                    <div className="text-center border-bottom p-4">
                                        <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team2} alt="Team member 2" />
                                        <h5>Hanad Muqadar</h5>
                                        <span>Data Scientist</span>
                                    </div>
                                    <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/syedhanad?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/hanad-muqaddar-91a450130/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="team-item bg-light rounded">
                                    <div className="text-center border-bottom p-4">
                                        <img className="img-fluid rounded-circle mb-4" style={{ height: '223px' }} src={team3} alt="Team member 3" />
                                        <h5>Muhammad Bilal</h5>
                                        <span>Frontend Developer</span>
                                    </div>
                                    <div className="d-flex justify-content-center p-4">
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.facebook.com/profile.php?id=100088430861303&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.twitter.com"><i className="fab fa-twitter"></i></a>
                       
                                    <a className="btn btn-square mx-1" target='_blank' href="https://www.linkedin.com/in/mirzabilal1029/"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
