import React from 'react'

function page404() {
  return (
    <div class="container-xxl py-5 bg-primary hero-header">
            <div class="container my-5 py-5 px-lg-5">
                <div class="row g-5 py-5">
                    <div class="col-12 text-center">
                        <h1 class="text-white animated slideInDown">404 Page</h1>
                        <hr class="bg-white mx-auto mt-0" style={{width: "90px"}}></hr>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center">
                            <li className="breadcrumb-item text-white active" aria-current="page">Home</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Pages</li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default page404