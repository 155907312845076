import React from 'react';

function ServicesCard({ index,icon, title, description,seconds }) {
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={seconds}>
      <div className="service-item d-flex flex-column text-center rounded">
        <div className="service-icon flex-shrink-0">
          <i className={icon}></i>
        </div>
        <h5 className="mb-3">{title}</h5>
        <p className="m-0">{description}</p>
        <a className="btn btn-square" href="#"><i className="fa fa-arrow-right"></i></a>
      </div>
    </div>
  );
}

export default ServicesCard;