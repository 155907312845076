import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from "./components/navbar"
import ScrollToTop from './components/ScrollToTop';
import About from "./views/About";
import Home from './views/home';
import Services from "./views/services";
import Contact from "./views/contact";
// import 'animate.css/animate.css';
import Page404 from "./views/page404";
import WOW from "wowjs";

import Footer from './components/footer';
import "./styles/bootstrap.min.css"

import "./styles/home.css"

function App() {
 
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />


        <Route path="/contact" element={<Contact />} />

        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
   
      <Footer />
    </Router>

  );
}

export default App;
