import React, { useEffect, useRef, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
function Contact() {
  const middleSectionRef = useRef(null);
  const location = useLocation();
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
   
    if (section === 'middle') {
      middleSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Backend URL:', process.env);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-email`, {
        subject: formData.subject,
        from_email: formData.email,
        full_name: formData.name,
        body: formData.message
      });
    
      toast.success('Your message has been sent successfully!',{
        autoClose: 2000,
        onClose: () => navigate('/')
      })
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    } catch (error) {
    
      toast.error('Failed to send Message');
    }
  };

  return (
    <>
  <ToastContainer/>
      <div className="container-xxl py-5 bg-primary hero-header">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white animated slideInDown">Contact Us</h1>
              <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }}></hr>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item text-white active" aria-current="page">Home</li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Pages</li>
                  <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div ref={middleSectionRef} className="container-xxl ">
        <div className="container  px-lg-5">
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <p className="section-title text-secondary justify-content-center"><span></span>Contact Us<span></span></p>
            <h1 className="text-center mb-5">Contact For Any Query</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                <p className="text-center mb-4">We'd love to hear from you! Whether you have questions, need support, or want to discuss your next project, feel free to reach out.</p>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required></input>
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required></input>
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required></input>
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: '150px' }} value={formData.message} onChange={handleChange} required></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
